
import {send as logSend, triggerPV, logMixin} from 'smith-log';

export default {
  name: 'menu-h5',
  components: {},
  mixins: [logMixin],
  data() {
    return {
      isRecruiter: true,
      showMenu: false,
      navItems: [
        {
          "name": "home",
          "label": "Home",
          "route": "/",
        },
        {
          "name": "jobseeker",
          "label": "For Job Seekers",
          "route": "/jobseeker",
          "eventTracking": "e_re_website_jobseeker"
        },
        {
          "name": "recruiter",
          "label": "For Employers",
          "route": "/recruiter",
          "eventTracking": "e_ca_website_recruiter"
        },
        {
          "name": "reviews",
          "label": "Legit Reviews",
          "route": "/hirey-reviews",
        },
        {
          "name": "blogs",
          "label": "Blogs",
          "route": "/blog/list",
        },
        {
          "name": "about",
          "label": "About Product",
          "route": "/homepage",
        },
      ],
      activeNav: '',
    }
  },
  watch: {
    '$route.path': function (newPath) {
      this.setActiveNav(newPath)
    },
    activeNav: function (nav) {
      if (nav === 'recruiter') {
        this.isRecruiter = true
      } else if (nav === 'jobseeker') {
        this.isRecruiter = false
      }
    }
  },
  fetch() {
    if (this.$route.path.indexOf('/apply') > -1) {
      this.isApply = true
    }
    if (this.$route.path.indexOf('/recruiter') > -1) {
      this.switchBthText = "I'm a Job seeker"
      this.isRecruiter = true
    } else if (this.$route.path.indexOf('/jobseeker') > -1) {
      this.switchBthText = "I'm a Recruiter"
      this.isRecruiter = false
    } else {
      this.switchBthText = "I'm a Recruiter"
      this.isRecruiter = false
    }
    if (this.$route.path.indexOf('/download') > -1) {
      this.isHome = false
      this.pageText = 'Home'
    } else {
      this.isHome = true
      this.pageText = 'Download App'
    }
  },
  mounted() {
    window.addEventListener('popstate', this.popstate, false);
    this.setActiveNav(this.$route.path)
  },
  destroyed() {
    window.removeEventListener('popstate', this.popstate, false)
  },
  activated() {
  },
  methods: {
    goHomepage() {
      this.$router.push('/')
    },
    setActiveNav(routePath) {
      console.log("current rout path", routePath)
      if (routePath === '/recruiter') {
        this.activeNav = "recruiter"
        this.isRecruiter = true
      } else if (routePath === '/jobseeker') {
        this.activeNav = "jobseeker"
        this.isRecruiter = false
      } else if (routePath === '/') {
        this.activeNav = "home"
      } else if (routePath === '/hirey-reviews' || routePath === '/hirey-app-reviews') {
        this.activeNav = "reviews"
      } else if (routePath === '/homepage') {
        this.activeNav = "about"
      } else if (routePath === '/blog/list') {
        this.activeNav = "blogs"
      }
      console.log("active nav", this.activeNav)
    },
    popstate() {
      if (window.location.href.indexOf('/recruiter') > -1) {
        this.isRecruiter = true
      }
      if (window.location.href.indexOf('/jobseeker') > -1) {
        this.isRecruiter = false
      }
    },
    downloadApp() {
      this.$router.push('/download');
      if (this.isRecruiter) {
        logSend('MC', 'e_re_website_download', {
          region_id: 1
        })
      } else {
        logSend('MC', 'e_ca_website_download', {
          region_id: 1
        })
      }
    },
    selectNav(e) {
      this.activeNav = e.name;
      this.$router.push(e.route);
      if (e.eventTracking) {
        logSend('MC', e.eventTracking, {
          region_id: 1
        })
      }
      this.showMenu = false
    },
    goWebapp() {
      window.open('https://www.hirey.com/web/for-you', '_self')
    },
  }
}
