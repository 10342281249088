
import { send as logSend } from 'smith-log';
export default {
  name: 'WebB',
  data () {
    return {
      downloadUrlApple: 'https://hireyapp.onelink.me/7R9y/xgxetvna',
    //   downloadUrlGoogle: 'https://play.google.com/store/apps/details?id=com.hirey&pli=1'
      downloadUrlGoogle: 'https://hireyapp.onelink.me/7R9y/swv5jwsa'
    }
  },
  mounted() {
    // const link = encodeURIComponent('http://workandroid.com/jd.php?' + 'region=1')
    // const link1 = encodeURIComponent('https://hirey.com')
    // const androidUrl = 'https://hirey.page.link/?ofl=' + link1 + '&apn=com.hirey' + '&ibi=jp.co.newsmr' + '&isi=6443807524' + '&efr=1' + '&link=' + link
    // this.downloadUrlGoogle = androidUrl
    // console.log('url======>', this.downloadUrlGoogle)
  },
  methods: {
    downloadBth (url) {
    //   window.open(url, '_blank')
      if (url === this.downloadUrlApple) {
        gtag('event', 'capage_appstore_click' )
        window.open(url, '_blank')
        // this.$ga.event('capage_appstore_click', 'click', 'button', {
        //     hitCallback: () => window.open(url, '_blank')
        // })
        console.log('apple')
        logSend('MC', 'e_ca_website_appstore', {
            region_id: 1
        })
      } else {
        gtag('event', 'capage_googleplay_click' )
        window.open(url, '_blank')
        // this.$ga.event('capage_googleplay_click', 'click', 'button', {
        //   hitCallback: () => window.open(url, '_blank')
        // })
        console.log('google')
        logSend('MC', 'e_ca_website_googleplay', {
            region_id: 1
        })
      }
    },
    startBth() {
        gtag('event', 'capage_startnow_click', { event_callback: (id) => {
            if (id === 'G-2Q1RRN8XGV') {
                this.$router.push('/download')
            }
        }, event_timeout: 1000 })
        // this.$ga.event('capage_startnow_click', 'click', 'button', {
        //   hitCallback: () => this.$router.push('/download')
        // })
    }
  }
}
