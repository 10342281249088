import { render, staticRenderFns } from "./MenuH5.vue?vue&type=template&id=8eb41374&scoped=true&"
import script from "./MenuH5.vue?vue&type=script&lang=js&"
export * from "./MenuH5.vue?vue&type=script&lang=js&"
import style0 from "./MenuH5.vue?vue&type=style&index=0&id=8eb41374&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8eb41374",
  null
  
)

export default component.exports