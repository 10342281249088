import { render, staticRenderFns } from "./index.vue?vue&type=template&id=1b993dda&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=1b993dda&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b993dda",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MenuH5: require('/usr/local/hirey/prod/server/h5/components/MenuH5.vue').default,SearchH5: require('/usr/local/hirey/prod/server/h5/components/SearchH5.vue').default,WebCH5: require('/usr/local/hirey/prod/server/h5/components/WebCH5.vue').default,FooterUsH5: require('/usr/local/hirey/prod/server/h5/components/FooterUsH5.vue').default})
