
export default {
    head() {
        return {
            title: 'The #1 Job Finder App for Sales Job Seekers | Search Jobs Online - Hirey',
            meta: [
                {hid: 'description', name: 'description', content: 'Are you looking for new job opportunities? Hirey offers the most advanced, easy to use job finder app for startups and businesses. Download today! - Hirey'}
            ]
        }
    },
    data() {
        return {
            scrollTop: 0
        }
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll)
        this.listenerFunction()
    },
    destroyed() {},
    beforeDestroy() {
        this.beforeDestroy()
    },
    methods: {
        handleScroll() {
             Math.floor(document.documentElement.scrollTop || document.body.scrollTop);
        },
        listenerFunction(e) {
            document.addEventListener("scroll", this.handleScroll, true);
        },
        beforeDestroy() {
            document.removeEventListener("scroll", this.listenerFunction);
        },
        goTop(){
    	    this.$refs.scrollBox.scrollTop = 0
        }
    }
}
